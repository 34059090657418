/* src/components/UserInfo.css */

.user-info {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
}

.user-info h3 {
  margin-top: 0;
  color: #2a5298;
}

.user-info p {
  font-size: 1.1em;
  color: #555;
  margin: 10px 0;
}
