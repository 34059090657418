/* src/pages/Dashboard.css */

/* Contenedor principal del Dashboard */
.dashboard-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f4f6f8;
  font-family: Arial, sans-serif;
}

/* Encabezado del Dashboard */
.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2a5298;
  color: #fff;
  padding: 20px 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Título en el encabezado */
.dashboard-header h1 {
  margin: 0;
  font-size: 1.8em;
}

/* Botón de cerrar sesión */
.logout-button {
  background-color: #ff5722;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #e64a19;
}

.logout-button:disabled {
  background-color: #ffa07a;
  cursor: not-allowed;
}

/* Sección principal del Dashboard */
.dashboard-main {
  flex: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Título en la sección principal */
.dashboard-main h2 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #333;
}

/* Estilo para mensajes de error */
.error {
  color: red;
  margin-top: 10px;
  text-align: center;
}
