/* src/pages/LandingPage.css */

/* Estilo general de la landing page */
.landing-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #1e3c72 0%, #2a5298 100%);
  color: #fff;
  text-align: center;
  padding: 0 20px;
  box-sizing: border-box;
}

/* Estilos para el header */
.landing-header {
  max-width: 600px;
}

/* Título principal */
.landing-title {
  font-size: 3em;
  margin-bottom: 20px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* Subtítulo */
.landing-subtitle {
  font-size: 1.5em;
  margin-bottom: 30px;
  line-height: 1.4;
}

/* Contenedor de botones */
.landing-buttons {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Estilos comunes para botones */
.btn {
  padding: 12px 20px;
  font-size: 1em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  transition: background 0.3s, transform 0.2s;
}

/* Botón primario */
.btn-primary {
  background-color: #ff5722;
  color: #fff;
}

.btn-primary:hover {
  background-color: #e64a19;
  transform: translateY(-2px);
}

/* Botón secundario */
.btn-secondary {
  background-color: #ffffff;
  color: #2a5298;
  border: 2px solid #fff;
}

.btn-secondary:hover {
  background-color: #f1f1f1;
  border-color: #e0e0e0;
  transform: translateY(-2px);
}

/* Responsividad */
@media (min-width: 600px) {
  .landing-buttons {
    flex-direction: row;
    justify-content: center;
  }

  .btn {
    width: 150px;
  }
}
