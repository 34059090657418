/* frontend/src/pages/Login.css */

.login-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
}

.login-container form {
  display: flex;
  flex-direction: column;
}

.login-container input {
  margin-bottom: 10px;
  padding: 8px;
}

.login-container button {
  padding: 10px;
}

.register-link {
  text-align: center;
  margin-top: 15px;
}

.register-link a {
  color: blue;
  text-decoration: underline;
}


/* Botón de Google */
.google-button {
  background-color: #4285f4;
  color: #fff;
  margin-top: 10px;
  padding: 10px;
  border: none;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
}

/* Botón de GitHub */
.github-button {
  background-color: #333;
  color: #fff;
  margin-top: 10px;
  padding: 10px;
  border: none;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
}

/* Estilo al pasar el ratón por encima */
.google-button:hover {
  background-color: #357ae8;
}

.github-button:hover {
  background-color: #24292e;
}
